.wrap-footer {
    width: 100%;
    height: auto;
    
}

.footer-container {
    text-align: center;
}

.footer-container > p {
    font-size: 11px;
    color: #929292;
}


@media screen and (min-width: 1901px) {
    .wrap-footer {
        margin: 30px auto ;
    }
}
@media screen and (max-width: 1900px) {
    .wrap-footer {
        margin: -30px auto 30px;
    }
}
@media screen and (max-width: 576px) {
    .wrap-footer {
        margin: -30px auto 30px;
    }
}