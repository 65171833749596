.wrap-landing {
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0), rgba(255, 255, 255,  0.5)), url("../images/bg5.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


.nav-wrapper {
    width: 90%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-container-1 > a > img {
    width: 150px;
    height: auto;
}

.nav-container-2 {
    display: flex;
    
}

.nav-content-lang, .nav-content-priv {
    display: flex;
    height: 32px;
    margin-left: 20px;
    padding: 2px 14px 0;
    border-radius: 8px;
    background-color: #f2f2f2;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    border: 1px solid #f2f2f2;
    transition: all 0.2s ease-in-out;
    z-index: 10;
}

.nav-content-lang:hover, .nav-content-priv:hover {
    color: #1a8468;
    border: 1px solid #1a8468;
}

.nav-content-links {
    font-size: 12px;  
    font-weight: bold;
    display: flex;
    align-items: center;
}

.navlink-icon-1 {
    color: #1e866a;
    font-size: 14px;
    margin-right: 10px;
}


.nav-content-links svg {
    color: #1a8468;
    font-size: 14px;
    margin: -2px 10px 0 0px;
}


/* modal */
.modal-backdrop.show {
    opacity: 0.7;
}

.modal {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    display: flex !important;
    align-items: center;
}

.modal-header {
    border: none;
    padding: 20px !important;
}
.modal .btn-close {
    width: 12px;
}

.modal-content {
    width: 400px;
    border: none;
    border-radius: 10px;
    outline: 0;
}

.modal-body {
    padding: 20px 20px 0 !important;
}

.lang-select {
    margin: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}
.lang-select > svg {
    color: #1a8468;
    font-size: 22px;
}

.modal-title h4 {
    font-size: 16px;
}
.lang-option-1 {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
}
.lang-option-2 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9b9b9b;
}


/* ********* */
.wrap-land-body {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
}

.landbody-container {
    width: 80%;
    max-width: 1440px;
    margin: 0 auto;
    animation: slide_up 3s ease;
}

.lb-content-p-1 {
    font-family: 'Russo One', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1a8468;
    margin-bottom: 20px;
}

.lb-content-h1 {
    font-size: 90px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Russo One', sans-serif;
    line-height: normal;
    letter-spacing: normal;
    color: #1a8468;
    margin-bottom: 0;
}

.lb-content-h1-mb {
    display: none;
}

.lb-content-p-2 {
    width: 300px;
    font-family: 'Russo One', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #3d3d3d;
    margin: -15px 0 30px;
}

.lb-download-app > a > img {
    width: 150px;
    height: auto;
    margin-right: 12px;
}

.waviy {
    position: relative;
}
.waviy span {
    position: relative;
    display: inline-block;
    font-size: 40px;
    font-weight: 900;
    color: #f7dc6f;
    text-transform: uppercase;
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i))
}
@keyframes flip {
    0%, 70% {
        transform: rotateY(360deg) 
    }
}

/*  */
.lb-content-h1 > span:before {
    /* font-style: italic; */
    font-family: 'Russo One', sans-serif;
    content: '2D';
    animation: textrotation infinite 10s;
    color: #f7dc6f;
}

@keyframes textrotation {
    0% {
        content: '2D';
    }
    33% {
        content: '3D';
    }
    66% {
        content: '2D3D';
    }

}

@keyframes slide_up {
    0% {
        transform: translateY(200px);
    }
    100% {
        transform: translateY(0);
    }
}




/* responsive  */
@media screen and (max-width: 1901px) {
    
    @keyframes slide_up {
        0% {
            transform: translateY(150px);
        }
        100% {
            transform: translateY(0);
        }
    }
}

@media screen and (max-width: 1200px) {

    .wrap-landing {
        height: auto;
        background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8), rgba(255, 255, 255,  0)), url("../images/bg5.png");
        background-position: top right;
        background-size: 180%;
    }
}


@media screen and (max-width: 576px) {
    .wrap-landing {
        height: auto;
        background-image: url("../images/bg5.png");
        background-position: top right;
        background-size: 180%;
    }
    .nav-container-1  > a > img {
        width: 120px;
    }

    .nav-content-lang {
        padding: 6px 8px 2px 10px;
        height: auto;
        border: 1px solid #1a8468;
    } 
    .nav-content-priv {
        padding: 6px 10px 2px;
        height: auto;
        border: 1px solid #1a8468;
    }

    .navlink-icon-1 {
        font-size: 18px;
        margin: 0;
    }
    .nav-content-links > p {
        display: none;
    }
    .nav-content-links svg {
        font-size: 18px;
        margin: -3px 0 0;
    }

    .wrap-land-body {
        height: 50vh;
        margin-top: 250px;
    }

    .landbody-container {
        width: 90%;
        text-align: center;
        animation: none;
    }

    .lb-content-p-1 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .lb-content-h1 {
        font-size: 40px;
        margin-bottom: 10px;
    }

    .lb-content-h1 {
        display: none !important;
    }
    
    .lb-content-h1-mb {
        display: block;
        font-family: 'Russo One', sans-serif;
        font-size: 46px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -2px;
        color: #1a8468;
        text-align: center;
        margin: 0 auto 10px;
    }

    .lb-content-p-2 {
        width: 80%;
        font-size: 12px;
        margin: 0 auto 32px;
    }

    .waviy {
        display: none;
    }

    .modal-dialog, .modal-content {
        width: 90%;
        margin: 0 auto;
    }
}