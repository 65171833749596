.wrap-privacy {
    width: 100%;
    height: auto;
    background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95), rgba(255, 255, 255,  0.9)), url("../images/bg4.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.priv-nav-wrapper {
    width: 90%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.priv-nav-container-1 {
    display: flex;
}

.priv-nav-back-btn {
    text-decoration: none;
    color: #1e866a;
}
.priv-navlink-icon-1:hover {
    color: #01b888;
}

.priv-nav-container-1 > p {
    font-size: 18px;
    font-weight: bold;
    color: #1a8468;
    margin: -2px 0 0 30px;
}

.priv-nav-container-2 {
    display: flex;
    
}

.priv-nav-content-lang, .priv-nav-content-priv {
    display: flex;
    height: 32px;
    margin-left: 20px;
    padding: 2px 14px 0;
    border-radius: 8px;
    background-color: #f2f2f2;
    text-decoration: none;
    color: #000;
    border: 1px solid #f2f2f2;
    transition: all 0.2s ease-in-out;
}

.priv-nav-content-lang:hover, .priv-nav-content-priv:hover {
    color: #1a8468;
    border: 1px solid #1a8468;
}

.priv-nav-content-links {
    font-size: 12px;  
    font-weight: bold;
    display: flex;
    align-items: center;
}

.priv-navlink-icon-2 {
    color: #1e866a;
    font-size: 14px;
    margin-right: 10px;
}


.wrap-priv-body {
    width: 80%;
    max-width: 1440px;
    height: auto;
    margin: 30px auto 0;
}

.priv-heading > h1 {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-bottom: 30px;
}

.priv-content-container {
    margin-bottom: 60px;
}

.priv-content-section {
    margin: 0 auto 30px;
}

.priv-content-section > h3 {
    font-size: 14px;
    font-weight: bold;
    color: #2b2b2b;
    margin-bottom: 12px;
}

.priv-content-section > p {
    font-size: 13px;
    font-weight: normal;
    color: #a3a3a3;
    margin-bottom: 12px;
}
.priv-content-section > p > a {
    font-size: 13px;
    font-weight: normal;
    color: #1a8468;
    margin-bottom: 12px;
}
.priv-content-section > p > a:hover {
    color: #000;
}



@media screen and (max-width: 576px) {

    .priv-nav-content-fb {
        padding: 6px 8px 2px 10px;
        height: auto;
    } 
    .priv-nav-content-priv {
        padding: 6px 10px 2px;
        height: auto;
        border: 1px solid #1a8468;
    }

    .priv-navlink-icon-2 {
        font-size: 18px;
        margin: 0;
    }
    .priv-nav-content-links > p {
        display: none;
    }

    .wrap-priv-body {
        width: 90%;
    }

}