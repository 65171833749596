.PageError {
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0), rgba(255, 255, 255,  0.5)), url("../images/bg5.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PageError > h1 {
    color: #1a8468;
    font-size: 26px;
    margin: 0 30px 0 0;
    padding-right: 30px;
    border-right: 1px solid #000;
}

.PageError > div > p {
    color: #1a8468;
    font-size: 16px;
    margin: 0 0 24px;
}

.b-to-homepage-btn {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #1a8468;
    text-decoration: none;
    padding: 12px 40px;
    border: 2px solid #1a8468;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.b-to-homepage-btn:hover {
    color: #1a8468;
    background-color: #f8f8f8;
    border: 2px solid #1a8468;
   
}


@media screen and (max-width: 576px) {
    .PageError {
        height: 80vh;
        background-image: url("../images/bg5.png");
        background-position: top right;
        background-size: 180%;
    }

    .PageError > div, .PageError > h1 {
        margin-top: 300px;
    }


}