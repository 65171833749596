

:root {
    --color-primary: #52be80;
    --color-secondary: #52be80;
    --color-tertiary: #27ae60;
    --color-quaternary: #229954;
    --color-quinary: #229954;
    
    --color-first: #f7dc6f;
    --color-second: #f7dc6f;
    --color-third: #f4d03f;
    --color-fourth: #FCC700;
    --color-fifth: #f1c40f;
    
}

.content > h1 {  
    display: flex;
    // align-content: center;
    // justify-content: center;
}

.text_shadows {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary),
        9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
    // font-family: 'Press Start 2P', cursive !important;
    font-family: 'Russo One', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 50px;
    // text-align: center;
    margin: 30px 0;
    color: var(--color-primary);
    animation: greenshadow 1.2s ease-in infinite, move 1.2s ease-in infinite;
    letter-spacing: 0;
}


.content span {
    text-shadow: 3px 3px 0 var(--color-second), 6px 6px 0 var(--color-third),
        9px 9px var(--color-fourth), 12px 12px 0 var(--color-fifth);
    // font-family: 'Press Start 2P', cursive !important;
    font-family: 'Russo One', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 140px;
    // text-align: center;
    margin: -30px 0 0 30px;
    color: var(--color-first);
    animation: yellowshadow 1.2s ease-in infinite, move 1.2s ease-in infinite;
    letter-spacing: -2px;
}

@keyframes greenshadow {
    0% {
        text-shadow: none;
    }
    10% {
        text-shadow: 3px 3px 0 var(--color-secondary);
    }
    20% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary);
    }
    30% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }
    40% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
        12px 12px 0 var(--color-quinary);
    }
    50% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
        12px 12px 0 var(--color-quinary);
    }
    60% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
        12px 12px 0 var(--color-quinary);
    }
    70% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
    }
    80% {
        text-shadow: 3px 3px 0 var(--color-secondary),
        6px 6px 0 var(--color-tertiary);
    }
    90% {
        text-shadow: 3px 3px 0 var(--color-secondary);
    }
    100% {
        text-shadow: none;
    }
}

@keyframes yellowshadow {
    0% {
        text-shadow: none;
    }
    10% {
        text-shadow: 3px 3px 0 var(--color-second);
    }
    20% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third);
    }
    30% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third), 9px 9px var(--color-fourth);
    }
    40% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third), 9px 9px var(--color-fourth),
        12px 12px 0 var(--color-fifth);
    }
    50% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third), 9px 9px var(--color-fourth),
        12px 12px 0 var(--color-fifth);
    }
    60% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third), 9px 9px var(--color-fourth),
        12px 12px 0 var(--color-fifth);
    }
    70% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third), 9px 9px var(--color-fourth);
    }
    80% {
        text-shadow: 3px 3px 0 var(--color-second),
        6px 6px 0 var(--color-third);
    }
    90% {
        text-shadow: 3px 3px 0 var(--color-second);
    }
    100% {
        text-shadow: none;
    }
}


@keyframes move {
    0% {
        transform: translate(0px, 0px);
    }
    40% {
        transform: translate(-12px, -12px);
    }
    50% {
        transform: translate(-12px, -12px);
    }
    60% {
        transform: translate(-12px, -12px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
