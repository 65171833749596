/* Config */
$color-bg: #111;
$color-particle: #229954;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 600s;

/* Pauls awesome mixin */
@function particles($max) {
   $val: 0px 0px $color-particle;
   @for $i from 1 through $max {
      $val: #{$val},
      random($spacing)+px random($spacing)+px $color-particle;
   }
   @return $val;
}

@mixin particles($max) {
   box-shadow: particles($max);
}

/* Styles 
html, body {
    height: 100%;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    overflow: hidden;
}*/

.page-bg,
.animation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    // width: 100%;
    // height: 100%;
}

.page-bg { 
    // background: $color-bg url("https://images.unsplash.com/photo-1562729562-679ab5311bbf?dpr=1&auto=format&fit=crop&w=1920&h=1080&q=100&cs=tinysrgb&crop=") no-repeat top center;
    background-size: cover;
    z-index: -1;
}

.particle,
.particle:after {
    background: transparent;
}

.particle:after {
    position: absolute;
    content: "";
    top: $spacing;
}

.particle-1 {
    animation: animParticle $time-1 linear infinite;
    @include particles(600);
    height: 1px;
    width: 1px;
    // border-radius: 20px;
}

.particle-1:after {
    @include particles(600);
    height: 1px;
    width: 1px;
    // border-radius: 20px;
}

.particle-2 {
    animation: animParticle $time-2 linear infinite;
    @include particles(200);
    height: 2px;
    width: 2px;
    // border-radius: 20px;
}

.particle-2:after {
    @include particles(200);
    height: 2px;
    width: 2px;
    // border-radius: 20px;
}

.particle-3 {
    animation: animParticle $time-3 linear infinite;
    @include particles(100);
    height: 3px;
    width: 3px;
    // border-radius: 20px;
}

.particle-3:after {
    @include particles(100);
    height: 3px;
    width: 3px;
    // border-radius: 20px;
}

.particle-4 {
    animation: animParticle $time-4 linear infinite;
    @include particles(400);
    height: 1px;
    width: 1px;
    // border-radius: 20px;
}

.particle-4:after {
    @include particles(400);
    height: 1px;
    width: 1px;
    // border-radius: 20px;
}

@keyframes animParticle {
    from { 
        transform: translateY(0px); 
    }
    to   { 
        transform: translateY($spacing * -1); 
    }
}

.page-wrapper {
    text-align: center;
    color: #fff;
    z-index: 2;
}

/**
 * CODEPEN PROMOTION
 * unrelated to particle system
 */

.codepen-promo:active, .codepen-promo:hover, .codepen-promo:link, .codepen-promo:visited {
    bottom: 30px;
    display: block;
    position: absolute;
    right: 30px;
    width: 200px;
    z-index: 99;
}
  
.codepen-promo-image {
    width: 100%;
}

.unsplash-promo:active, .unsplash-promo:hover, .unsplash-promo:link, .unsplash-promo:visited {
    bottom: 30px;
    display: block;
    position: absolute;
    left: 30px;
    width: 400px;
    z-index: 99;
    color: #666;
    text-decoration: none;
}